/**
 * Sambiagio - LazyLoad
 *
 * JS pour la gestion de LazyLoad
 *
 * @since       24.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/*******************************/
/********** FUNCTIONS **********/
/*******************************/

jQuery( function() {
    jQuery( '.wpb_wrapper .vc_single_image-wrapper img' ).lazyload();
} );

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery( document ).ready( function() {

//END jQuery( document ).ready
} );

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/***********************************/
/********** WINDOW SCROLL **********/
/***********************************/

jQuery( window ).scroll( function() {


//END jQuery( window ).scroll
} );
