/**
 * Sambiagio - Two Domains Block
 *
 * JS pour la gestion du block Two Domains
 *
 * @since       04.11.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/*******************************/
/********** VARIABLES **********/
/*******************************/

/* 
 * @param Obj twoDomainsBlockContainer                    main container
 * @param Obj twoDomainsBlockContent                      content
 * @param Obj twoDomainsBlockBgImg                        background image wrapper
 * @param Obj twoDomainsBlockBgImgPic                     background image
 */

var twoDomainsBlockContainer = jQuery( '.two-domaines-block' );
var twoDomainsBlockContent = jQuery( '.two-domaines-block .two-domaines-content' );
var twoDomainsBlockBgImg = jQuery( '.two-domaines-block .two-domaines-content .bg-img' );
var twoDomainsBlockBgImgPic = jQuery( '.two-domaines-block .two-domaines-content .bg-img img' );

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/************************************/
/********** CONTAINER SIZE **********/
/************************************/

/* 
 * @return Bool 
 */

var twoDomainsBlockContaierSize = function() {
    
    if ( twoDomainsBlockBgImgPic.length > 0 ) {
        
        setTimeout( function() {
            
            var imgHeight = twoDomainsBlockBgImgPic.height();
            
            if ( imgHeight > 0 ) {
                
                twoDomainsBlockBgImg.height( imgHeight );
              
            //END if ( imgHeight > 0 )
            }
            
        //END setTimeout
        }, 250 );
     
    //END if ( twoDomainsBlockBgImgPic.length > 0 )
    }
    
//END twoDomainsBlockContaierSize 
};

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery( document ).ready( function() {
    
    twoDomainsBlockContaierSize();
    
//END jQuery( document ).ready
} );

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/****************************/
/********** RESIZE **********/
/****************************/

jQuery( window ).resize( function() {
    
    twoDomainsBlockContaierSize();
    
//END jQuery( window ).resize( function() 
} );
