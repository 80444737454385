/**
 * Sambiagio - Functions
 *
 * Fonctions JS
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/********************************************/
/********** ALERTES DANS UNE MODAL **********/
/********************************************/

/*
 * @param String row                        row de l'alèrte
 * @param String alert                      div de l'alèrte
 * @param String content                    contenu de l'alèrte
 * @param Bool hide                         masque ou affiche l'alèrte
 * @param String add                        classe à ajouter
 * @param String remove                     classe à supprimer
 * @param String msg                        message
 */

var modalAlert = function( row, alert, content, hide, add, remove, msg ) {

	if ( add.length > 0 ) {
		jQuery( alert ).addClass( add );
	}

	if ( remove.length > 0 ) {
		jQuery( alert ).removeClass( remove );
	}

	jQuery(content).html( msg );

	if ( hide === false ) {
		jQuery( row ).show( 'fast', 'swing' );
	} else {
		jQuery( row ).hide( 'fast', 'swing' );
	}

};

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/***********************************/
/********** DISPLAY MODAL **********/
/***********************************/

/*
 * @param String element                objet à afficher
 */

var displayModal = function( element ) {

    jQuery( element ).show( 'fast', 'swing' );

    if ( window.matchMedia( "(max-width: 991px)" ).matches ) {

        if ( jQuery( '.sidebar-nav' ).hasClass( 'in' ) ) {
            jQuery( '.sidebar-nav' ).removeClass( 'in' );
            jQuery( 'body' ).css( 'overflow', 'visible' );
            jQuery( 'body' ).css( 'position', 'relative' );
        }

        jQuery( 'html, body' ).animate( { scrollTop: 0 }, 'normal' );
    }

    if ( jQuery( '.overlay-body:visible' ).length === 0 ) {
        jQuery('.overlay-body').css('display', 'block');
    }

//END displayModal
};

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/********************************/
/********** CLOSE LINK **********/
/********************************/

jQuery(document).on('click', '.close-link', function(event) {

    event.preventDefault();

    var parentWrapper = jQuery( this ).parent();
    var parentGlobal = jQuery( parentWrapper ).parent();
    var modalForm = jQuery( parentGlobal ).find( 'form' );

    if ( parentGlobal.hasClass( 'modal-box' ) ) {

        var msgRow = jQuery( parentGlobal ).find( '.alert-row' );
        var msgAlert = jQuery( parentGlobal ).find( '.alert' );
        var msgContent = jQuery( msgAlert ).find( '.content' );

        modalAlert( msgRow, msgAlert, msgContent, true, 'alert-danger', 'alert-success', '' );

    }

    if ( modalForm.length > 0 ) {
        jQuery( modalForm ).find( "input[type=text], input[type=password], textarea" ).val( "" );
    }

    jQuery( parentGlobal ).hide( 'fast', 'swing' );
    jQuery( '.overlay-body' ).css( 'display', 'none' );

    return false;

//END .jQuery(document).on('click', '.close-link', function()
});

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/******************************************************************************/
/********** RETOURNE LA HAUTEUR MAXIMALE DAND UN ENSEMBLE D'ÉLÉMENTS **********/
/******************************************************************************/

/* 
 * @param Obj element                       objet
 * @param Str maxHeight                     hauteur maximale (avec px)
 */

var getElementsMaxHeight = function( element ) {
    
    var maxHeight = Math.max.apply( null, element.map( function() {
        return jQuery( this ).height();
    } ).get() );
    
    return maxHeight;
    
//END getElementsMaxHeight 
};