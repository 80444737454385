/**
 * Sambiagio - Forms
 *
 * JS pour la gestion des formulaires
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery( document ).ready( function() {

//END jQuery document ).ready
});

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/*************************/
/********** ACF **********/
/*************************/

( function( $ ) {
    
    if ( $( 'form.acf-form' ).length > 0 ) {
        acf.do_action( 'append', $( '#popup-id' ) );
    }

} )( jQuery );	
