/**
 * Sambiagio - Nice Scroll
 *
 * JS pour la gestion du Nice Scroll
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery( document ).ready( function() {
    
    /*********************************/
    /********** NICE SCROLL **********/
    /*********************************/
    
    jQuery( "html" ).niceScroll({
        cursorcolor: "#afafaf",
        cursorborder: "1px solid #afafaf",
        zindex: 2147483647,
        scrollspeed: 80,
        mousescrollstep: 80
    });

//END jQuery( document ).ready
});
