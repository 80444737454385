/**
 * Sambiagio - Picasa
 *
 * JS pour la gestion de Picasa
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery(document).ready(function() {
    
    /************************************/
    /********** GALERIE PICASA **********/
    /************************************/
    
    if ( jQuery( '.picasa-gallery-wrapper' ).length > 0 ) {
        
        /**********/
        /********** GALERIE POP UP **********/
        /**********/
        
        jQuery( document ).on( 'click', '.gallery-popup-link', function( event ) {
            
            event.preventDefault();
            
            var strUrl = '';
            var items = [];
            var feed = jQuery( this ).attr( 'data-feed' );

            jQuery.ajax({
                type : "POST",
                async: false,
                url : data_sage_js.ajaxurl,
                dataType: 'json',
                data: {
                    action: 'loadPicasaPhotosFromAlbumAjax',
                    feed: feed
                },
                success: function( data ) {

                    if ( data.answer === 1 ) {
                        
                        var photosLength = jQuery( data.content.photos ).length;
                        
                        jQuery.each( data.content.photos, function( i, val ) {
                            
                            items.push( {
                                src: val.src,
                                title: ''
                            });
                            
                            strUrl += val.src;
                            
                            if ( i < photosLength ) {
                                strUrl += ',';
                            }

                        //END jQuery.each( data.content, function( i, val )
                        });
                        
                        if ( strUrl !== '' ) {
                            
                            jQuery.magnificPopup.open( {
                                mainClass: 'mfp-fade',
                                items: items,
                                gallery:{
                                    enabled: true,
                                    tPrev: jQuery( this ).data( 'prev-text' ),
                                    tNext: jQuery( this ).data( 'next-text' )
                                },
                                type: 'image'
                            } );
                         
                        //END if ( strUrl !== '' )
                        }
                        
                    } else {

                        jQuery( '.picasa-gallery-wrapper' ).html( '<p>Une erreur s\'est produite ! - 1</p>' );
                        
                    //END else
                    }

                //END success
                },
                error: function () {

                    jQuery( '.picasa-gallery-wrapper' ).html( '<p>Une erreur s\'est produite ! - 2</p>' );

                }

            //END ajax
            });
            
            return false;

        //END jQuery( document ).on( 'click', '.gallery-popup-link', function( event )
        });
        
        /**********/
        /********** GALERIE STANDARD **********/
        /**********/
        
        if ( jQuery( '.standard-gallery' ).length > 0 ) {
            
            jQuery( '.standard-gallery-container' ).each(function() {
                
                jQuery( this ).magnificPopup({
                    delegate: 'a',
                    type: 'image',
                    gallery: {
                        enabled: true
                    }
                });
                
            //END jQuery( '.standard-gallery-container' ).each(function()
            });
         
        //END if ( jQuery( '.standard-gallery' ).length > 0 )
        }
     
    //END if ( jQuery( '.picasa-gallery-wrapper' ).length > 0 )
    }

//END jQuery(document).ready
});
