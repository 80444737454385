/**
 * Sambiagio - Tables
 *
 * JS pour la gestion des tableaux
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery( document ).ready( function() {

    /********************************/
    /********** DATA TABLE **********/
    /********************************/

    jQuery( '#sorting-data-table' ).dataTable({
        "paging": false,
        "searching": false,
        "info": false
    });
    
    /************************************/
    /********** TABLEAU EXPAND **********/
    /************************************/
    
    if ( jQuery( '.table-expand' ).length > 0 ) {
        
        jQuery( '.table-expand tr.table-hide' ).nextAll( 'tr' ).hide();
        
        jQuery( document ).on( 'click', '.table-expand .table-hide td a', function( event ) {
            
            event.preventDefault();
            
            var thisParent = jQuery( this ).closest( 'tr' );
            
            thisParent.nextAll( 'tr' ).fadeToggle( 'fast' );
            
            return false;
            
        //END .jQuery( document ).on( '.table-expand .table-hide .deploy-table', 'click', function() {
        });
        
    //END if ( jQuery( '.table-expand' ).length > 0 )
    }

//END jQuery( document ).ready
});
