/**
 * Sambiagio - Advertisements
 *
 * JS pour la gestion des publicités
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery(document).ready(function() {
    
    setTimeout( function() {
    
        jQuery( '.advertisement-page-block-img' ).each( function( index ) {

            var thisAd = jQuery( this );

            if ( thisAd.hasClass( 'left' ) ) {
                thisAd.animate( { left :0 }, 1000 );
            } else if ( thisAd.hasClass( 'right' ) ) {
                thisAd.animate( { right :0 }, 1000 );
            }

        //END jQuery( '.advertisement-page-block-img' ).each(function( index )
        });
        
    //END setTimeout
    }, 1000 );

//END jQuery(document).ready
});