/**
 * Sambiagio - Galleries
 *
 * JS pour la gestion des galeries d'images
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery( document ).ready( function() {
    
    jQuery( '.image-gallery-wrapper' ).magnificPopup( {
        delegate: 'a',
		type: 'image',
		closeOnContentClick: false,
		closeBtnInside: false,
		mainClass: 'mfp-with-zoom mfp-img-mobile',
		image: {
			verticalFit: true,
			titleSrc: ''
		},
		gallery: {
			enabled: true
		},
		zoom: {
			enabled: true,
			duration: 300,
			opener: function( element ) {
				return element.find( 'img' );
			}
		},
        callbacks: {
            afterClose: function() {
                jQuery( 'body' ).css( 'overflow', 'hidden' );
            }
        }
    //END jQuery( '.image-gallery-wrapper' ).magnificPopup   
    } );

//END jQuery( document ).ready
} );
