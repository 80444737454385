/**
 * Sambiagio - Navigation
 *
 * JS pour la gestion de la navigation
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** HIDE DROP DOWN **********/
/************************************/ 

function hideDropdown() {
    
    var li = jQuery( '#the-main-menu li.parent-dropdown' );

    li.each( function() {
        
        var parentToggle = jQuery( this ).children( 'a.parent-toggle' );
        var ulParent = jQuery( this ).children( 'ul.parent-dropdown-menu' );
        var liChild = ulParent.children( 'li.dropdown' );

        liChild.each( function() {

            var childToggle = jQuery( this ).children( 'a.child-toggle' );
            var childDropdown = jQuery( this ).children( 'ul.child-dropdown-menu' );

            childToggle.attr( 'aria-expanded', false );
            childDropdown.attr( 'aria-expanded', false );
            childToggle.removeClass( 'open' );
            childDropdown.removeClass( 'open' );

        });

        parentToggle.attr( 'aria-expanded', false );
        ulParent.attr( 'aria-expanded', false );
        jQuery( this).removeClass( 'open' );
        parentToggle.removeClass( 'open' );
        ulParent.removeClass( 'open' ); 

    });

    return false;
}


/*********************************************************************************/
/*********************************************************************************/

/********************************************/
/********** HANDLE CLICK DROP DOWN **********/
/********************************************/ 

function handleClickDropdown() {
    
    jQuery( '.navbar .dropdown a.parent-toggle + b.caret-mobile' ).click( function( e ) {

        if ( window.matchMedia( '( max-width: 767px )' ).matches ) {
        
            e.stopPropagation();
            e.preventDefault();

            hideDropdown();

            var caret = jQuery( this );
            var link = caret.prev( 'a.parent-toggle' );
            var currentDropdown = link.closest( 'li.parent-dropdown' );

            link.attr( 'aria-expanded', true );
            currentDropdown.attr( 'aria-expanded', true );

            currentDropdown.addClass( 'open' );
        
        }

        return false;
    });

    jQuery( '.navbar .dropdown ul li a.child-toggle + b.caret-mobile' ).click( function( e ) {

        if ( window.matchMedia( '( max-width: 767px )' ).matches ) {

            e.stopPropagation();
            e.preventDefault();

            hideDropdown();

            var caret = jQuery( this );
            var link = caret.prev( 'a.child-toggle' );
            var currentDropdown = jQuery( this ).next( 'ul.child-dropdown-menu' );

            var parent = link.closest( 'ul.parent-dropdown-menu' );
            var mainLi = parent.closest( 'li.parent-dropdown' );
            var parentToggle = parent.closest( 'a.parent-toggle' );

            link.attr( 'aria-expanded', true );
            currentDropdown.attr( 'aria-expanded', true );
            parent.attr( 'aria-expanded', true );
            parentToggle.attr( 'aria-expanded', true );
            
            mainLi.addClass( 'open' );
            parent.addClass( 'open' );
            currentDropdown.addClass( 'open' ); 

        }

        return false;
    } );

}

/*********************************************************************************/
/*********************************************************************************/

/********************************************/
/********** HANDLE HOVER DROP DOWN **********/
/********************************************/ 

function handleHoverDropdown() {

    jQuery( '.navbar li.parent-li:not(.dropdown) a' ).hover( function( e ) {
        if ( window.matchMedia( '( min-width: 768px )' ).matches ) {
            hideDropdown();
        }

        return false;
    });

    jQuery( '.navbar .dropdown a.parent-toggle' ).hover( function( e ) {

        if ( window.matchMedia( '( min-width: 768px )' ).matches ) {

            e.stopPropagation();
            e.preventDefault();
            hideDropdown();

            var link = jQuery( this );
            
            var mobileCaret = link.next( 'b.caret-mobile' );
            var dropDownUl = mobileCaret.next( 'ul.parent-dropdown-menu' );

            var li = link.closest( 'li.parent-dropdown' );

            link.attr( 'aria-expanded', true );
            li.attr( 'aria-expanded', true );

            li.addClass( 'open' ); 
            li.addClass( 'hover' );

            dropDownUl.hover(function( e ) {

                if ( window.matchMedia( '( min-width: 768px )' ).matches ) {

                    if ( !li.hasClass( 'hover' ) ) {
                        li.addClass( 'hover' );
                    }   

                }

            }, function( e ) {

                if ( window.matchMedia( '( min-width: 768px )' ).matches ) {

                    dropDownUl.attr( 'aria-expanded', false );
                    link.attr( 'aria-expanded', false );
                    li.attr( 'aria-expanded', false );

                    li.removeClass( 'hover' );
                    li.removeClass( 'open' );

                }

            });

        }

        return false;

    }, function() {

        if ( window.matchMedia( '( min-width: 768px )' ).matches ) {

            var link = jQuery( this );
            var li = link.closest( 'li.parent-dropdown' );

            setTimeout( function() {

                if ( !li.hasClass( 'hover' ) && !li.hasClass( 'hover-child' ) ) {
                    
                    link.attr( 'aria-expanded', false );
                    li.attr( 'aria-expanded', false );
        
                    li.removeClass( 'open' );
        
                }
                    
            }, 500 );

        }

        return false;

    });

    jQuery( '.navbar .dropdown ul li a.child-toggle' ).hover( function( e ) {

        if ( window.matchMedia( '( min-width: 768px )' ).matches ) {
          
            e.stopPropagation();
            e.preventDefault();

            var link = jQuery( this );
            var mobileCaret = jQuery( this ).next( 'b.caret-mobile' );
            var currentDropdown = mobileCaret.next( 'ul.child-dropdown-menu' );

            var parent = link.closest( 'ul.parent-dropdown-menu' );

            var mainLi = parent.closest( 'li.parent-dropdown' );
            var parentToggle = parent.find( 'a.parent-toggle' );

            link.attr( 'aria-expanded', true );
            currentDropdown.attr( 'aria-expanded', true );

            currentDropdown.addClass( 'open' );
            mainLi.addClass( 'hover-child' ); 

            currentDropdown.hover(function( e ) {

                if ( window.matchMedia( '( min-width: 768px )' ).matches ) {

                    if ( !mainLi.hasClass( 'hover-child' ) ) {
                        mainLi.addClass( 'hover-child' );
                    }  

                    currentDropdown.addClass( 'hover' );

                }

            }, function( e ) {

                if ( window.matchMedia( '( min-width: 768px )' ).matches ) {

                    currentDropdown.attr( 'aria-expanded', false );
                    mainLi.removeClass( 'hover-child' );
                    currentDropdown.removeClass( 'open' );
                    currentDropdown.removeClass( 'hover' );

                }

            });

        }

        return false;

    }, function( e ) {

        if ( window.matchMedia( '( min-width: 768px )' ).matches ) {

            var link = jQuery( this );
            var mobileCaret = jQuery( this ).next( 'b.caret-mobile' );
            var currentDropdown = mobileCaret.next( 'ul.child-dropdown-menu' );

            var parent = link.closest( 'ul.parent-dropdown-menu' );
            var mainLi = parent.closest( 'li.parent-dropdown' );

            setTimeout( function() {
                
                if ( !currentDropdown.hasClass( 'hover' ) ) {
                    link.attr( 'aria-expanded', false );
                    currentDropdown.attr( 'aria-expanded', false );
                    mainLi.removeClass( 'hover-child' );
                    currentDropdown.removeClass( 'open' );
                }
                    
            }, 100 );

        }

        return false;

    });

}

/*********************************************************************************/
/*********************************************************************************/

/**************************************/
/********** HANDLE DROP DOWN **********/
/**************************************/ 

function handleDropdown() {

    if ( window.matchMedia( '( max-width: 767px )' ).matches ) {

        handleClickDropdown();

    } else {

        handleHoverDropdown();

    }

}

/*********************************************************************************/
/*********************************************************************************/

/************************************************/
/********** HANDLE NAVIGATION POSITION **********/
/************************************************/

function handleNavigationPosition() {

    var header = jQuery( '.header-wrapper' );
    var scroll = jQuery( window ).scrollTop();
    var headerHeight = jQuery( 'header.post-header' ).outerHeight();

    if ( window.matchMedia( '( max-width: 767px )' ).matches ) {

        if ( !header.hasClass( 'not-fixed' ) && scroll < headerHeight ) {
            header.addClass( 'not-fixed' );
        } else if ( header.hasClass( 'not-fixed' ) && scroll >= headerHeight  ) {
            header.removeClass( 'not-fixed' );
        }

    } else {
        
        if ( header.hasClass( 'not-fixed' ) ) {
            header.removeClass( 'not-fixed' );
        }

    }

}

/*********************************************************************************/
/*********************************************************************************/

/****************************/
/********** RESIZE **********/
/****************************/

jQuery( window ).resize(function() {
    hideDropdown();
    handleDropdown();
    handleNavigationPosition();
});

/*********************************************************************************/
/*********************************************************************************/

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery( document ).ready( function() {

    /*******************************************/
    /********** PARENT DROPDOWN ACTIF **********/
    /*******************************************/

    handleDropdown();
    handleNavigationPosition();
        
    /*********************************************************************************/
    /*********************************************************************************/
    
    /*********************************/
    /********** MENU BURGER **********/
    /*********************************/
    
    if ( jQuery( '#navbar-toggle' ).length > 0 && jQuery( '#main-burger-menu' ).length > 0 ) {
        
        var mainBurgerOpen = false;
        
        jQuery( '#navbar-toggle' ).click( function() { 
            
            if ( mainBurgerOpen === false ) {
                
                jQuery( '#main-burger-menu' ).fadeIn( 'fast' );
                jQuery( '#navbar-toggle' ).removeClass( 'collapsed' );
                jQuery( '.navbar-brand' ).css( 'z-index', '9000' );
                
                mainBurgerOpen = true;
                return true;
            
            //END if ( mainBurgerOpend === false )
            } else {
                
                jQuery( '#main-burger-menu' ).fadeOut( 'fast' );
                jQuery( '#navbar-toggle' ).addClass( 'collapsed' );
                jQuery( '.navbar-brand' ).css( 'z-index', '15000' );
                
                mainBurgerOpen = false;
                return false;
                
            //END else
            }

        //END jQuery( '#navbar-toggle' ).click
        } );

    //END if ( jQuery( '#navbar-toggle' ).length > 0 && jQuery( '#main-burger-menu' ).length > 0 )   
    }
    
//END jQuery( document ).ready
} );

/*********************************************************************************/
/*********************************************************************************/

/****************************/
/********** SCROLL **********/
/****************************/

jQuery( window ).scroll(function() {
    handleNavigationPosition();
});