/**
 * Sambiagio - Images
 *
 * JS pour la gestion des imagess
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery( document ).ready( function() {
    
    jQuery( '.image-popup' ).magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		mainClass: 'mfp-img-mobile',
		image: {
			verticalFit: true,
			titleSrc: ''
		},
        callbacks: {
            afterClose: function() {
                jQuery( 'body' ).css( 'overflow', 'hidden' );
            }
        }
    //END jQuery( '.image-popup' ).magnificPopup 
    } );
    
    jQuery( '.wpb_single_image:not(.link) a[target="_self"]' ).magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		mainClass: 'mfp-img-mobile',
		image: {
			verticalFit: true,
			titleSrc: ''
		},
        callbacks: {
            afterClose: function() {
                jQuery( 'body' ).css( 'overflow', 'hidden' );
            }
        }
    //END jQuery( '.image-popup' ).magnificPopup 
    } );
    
    jQuery( '.wpb_image_grid .wpb_image_grid_ul li a[target="_self"]' ).magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		mainClass: 'mfp-img-mobile',
		image: {
			verticalFit: true,
			titleSrc: ''
		},
        callbacks: {
            afterClose: function() {
                jQuery( 'body' ).css( 'overflow', 'hidden' );
            }
        }
    //END jQuery( '.wpb_image_grid .wpb_image_grid_ul li a[target="_self"]' ).magnificPopup 
    } );

//END jQuery( document ).ready
} );
