/**
 * Sambiagio - Products
 *
 * JS relatif aux produits
 *
 * @since       11.11.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/**************************************/
/********** GLOBAL VARIABLES **********/
/**************************************/

/*
 * @var Obj productsBySubcategoryBlock           block wrapper
 * @var Obj selector                            selector
 * @var Obj productSelectorOutput                output container
 * @var Obj productLoadingInformation            loading information
 */

var productsBySubcategoryBlock = jQuery('.products-by-subcategory-block');
var selector = jQuery('.attributes-selector');
var productSelectorOutput = jQuery('.products-by-subcategory-block .products-grid');
var productLoadingInformation = jQuery('.products-by-subcategory-block .loading-information');

/*********************************************************************************/
/*********************************************************************************/

/**********************************************/
/********** GET PRODUCTS BY TAXONOMY **********/
/**********************************************/

/*
 * @param String theTaxonomy                       taxonomy to use
 * @param Bool ignoreParent                        does term's parent has to be ignored?
 * @param Bool masterTax                           master taxonomy (eg : product category / brand )
 * @param String masterTaxName                     master taxonomy name
 * @param Int masterTermID                         master term's id (cancel masterTaxName)
 * @param Int childOf                              main term ID
 * @param Int price                                price
 * @param Int places                               places
 * @param Int displayPost
 * return Mixed                                    false || Object
 */

var getProductsByTaxonomy = function(theTaxonomy, ignoreParent, masterTax, masterTaxName, masterTermID, childOf, price, places, displayPost) {

    productLoadingInformation.fadeIn('fast');

    jQuery.ajax({
        type: "POST",
        async: true,
        cache: false,
        url: data_sage_js.ajaxurl,
        dataType: 'json',
        data: {
            action: 'getPostsByTaxonomyAjax',
            theTaxonomy: theTaxonomy,
            ignoreParent: ignoreParent,
            masterTax: masterTax,
            masterTaxName: masterTaxName,
            masterTermID: masterTermID,
            childOf: childOf,
            price: price,
            places: places,
            displayPost: displayPost
        },
        success: function(data) {

            productSelectorOutput.empty();

            if (data.answer === 1) {

                productSelectorOutput.html(data.output);

            } else {

                productSelectorOutput.html(data_sage_js.ajax_error);

                //END else
            }

            productLoadingInformation.fadeOut('fast');



            //END success
        },
        error: function() {

            productSelectorOutput.empty();
            productSelectorOutput.html(data_sage_js.ajax_error);
            productLoadingInformation.fadeOut('fast');

        }

        //END ajax
    });

    //END getProductsByTaxonomy
};

/*********************************************************************************/
/*********************************************************************************/

/***************************************/
/********** CHANGE BRANDS ROW **********/
/***************************************/

/*
 * @param Int action                action to do
 * @para String slug                slug to compare
 * @return Bool
 */

var getChangeBrandsRow = function(action, slug) {

    if (jQuery('.brands-by-category-block').length > 0) {

        switch (action) {

            case 1:

                if (typeof slug === 'undefined' || slug === null || slug === '') {
                    jQuery('.brands-by-category-block img').removeClass('darken');
                    return false;
                }

                jQuery('.brands-by-category-block img').each(function(i) {

                    var title = jQuery(this).attr('data-title');

                    var transformedSlug = slug.replace('-', '');

                    if (title.indexOf(slug) === -1 && title.indexOf(transformedSlug) === -1) {
                        jQuery(this).addClass('darken');
                    } else {
                        jQuery(this).removeClass('darken');
                    }
                });
                break;

            case 2:

                jQuery('.brands-by-category-block img').removeClass('darken');
                break;

        }

        return true;

    }

    return false;

};

/*********************************************************************************/
/*********************************************************************************/

/******************************/
/********** GET DATA **********/
/******************************/

/*
 * @return Array
 */

var getData = function() {

    var output = [];

    var selectedBrandOption = jQuery('#brand-selector option:selected');
    var selectedPriceOption = jQuery('#price-selector option:selected');
    var selectedPlacesOption = jQuery('#places-selector option:selected');

    output.masterTermID = selectedBrandOption.attr('data-id');
    output.childOf = selectedBrandOption.attr('data-subcategory');
    output.value = selectedBrandOption.val();
    output.slug = selectedBrandOption.attr('data-slug');
    output.price = selectedPriceOption.val();
    output.places = selectedPlacesOption.val();

    return output;

    //END getData
};

/*********************************************************************************/
/*********************************************************************************/

/****************************************/
/********** ON SELECTOR CHANGE **********/
/****************************************/

/*
 * @return Bool
 */

var onSelectorChange = function() {

    if (selector.length > 0) {

        selector.on('change', function() {

            var data = getData();

            var masterTermdID = null;
            var childOf = data.childOf;
            var slug = null;
            var price = null;
            var places = null;
            var action = 2;

            if (parseInt(data.value) === 0) {
                masterTermdID = null;
                slug = data.slug;
                action = 1;
            }

            if (data.value !== 'all') {
                masterTermdID = data.masterTermID;
                slug = data.slug;
                action = 1;
            }

            if (data.price !== 'all' && parseInt(data.price) !== 0) {
                price = data.price;
            }


            if (data.places !== 'all' && parseInt(data.places) !== 0) {
                places = data.places;
            }


            getProductsByTaxonomy('product_sub_category', 0, 1, 'product_brand', data.masterTermID, data.childOf, price, places, 1);
            getChangeBrandsRow(action, data.slug);
            return true;

            //END selector.on( 'change', function()
        });

        //END if ( jQuery( '#brand-selector' ).length > 0 ) 
    }

    return false;

    //END onSelectorChange
};

/*********************************************************************************/
/*********************************************************************************/

/*****************************************/
/********** INIT BRAND SELECTOR **********/
/*****************************************/

/*
 * @return Void
 */

var initSelector = function() {

    if (productsBySubcategoryBlock.length > 0) {

        onSelectorChange();

        //END if ( productsBySubcategoryBlock.length > 0 )
    }

    //END initSelector
};

/*********************************************************************************/
/*********************************************************************************/

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery(document).ready(function() {
    console.log('ready');
    initSelector();

    //END jQuery( document ).ready
});