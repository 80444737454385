/**
 * Sambiagio - Typography
 *
 * JS pour la gestion des éléments textuels
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery(document).ready(function() {

    /********************************/
    /********** HYPHENATOR **********/
    /********************************/

    jQuery( "p, .hat-txt, .hat-txt-border" ).each(function() {
        jQuery( this ).addClass( "hyphenate" );
    });
    
    Hyphenator.run();

//END jQuery(document).ready
});
