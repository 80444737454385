/**
 * Sambiagio - Google Map
 *
 * JS pour la gestion du Google Map
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/***********************************************/
/********** INITILISATION DE LA CARTE **********/
/***********************************************/

function googleMapInitialize() {

    /**********************************************/
    /********** DÉFINITION DES VARIABLES **********/
    /**********************************************/

    var map;

    var country = new google.maps.LatLng( 46.900231, 8.5402035 );
    var bounds = new google.maps.LatLngBounds();

    var image = data_sage_js.siteurl + 'wp-content/themes/sambiagio/dist/images/markers/map-marker-min.png';

    var markers = [
        [ 'Chassot Concept', 46.848399, 6.856438 ],
    ];

    var infoWindowContent = [
        [ '<div class="info_content"><h3>Chassot Concept</h3><pChamp de la Vigne 3<br />470 Estavayer-le-Lac</p></div>' ],
    ];

    var centerBounds = false;
    var drag = true;

    if ( window.matchMedia( '(max-width: 1025px)' ).matches ) {

        drag = false;

    //END if (window.matchMedia('(min-width: 1025px)').matches
    }

    /********************************************/
    /********** GÉNÉRATION DE LA CARTE **********/
    /********************************************/

    var mapOptions = {
        mapTypeId: 'roadmap',
        scrollwheel: false,
        panControl: true,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        overviewMapControl: false,
        rotateControl: false,
        draggable: drag,
        center: new google.maps.LatLng( 46.848399, 6.856438 ),
        zoom: 11,
        styles: [
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f7f1df"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#d0e3b4"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural.terrain",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.medical",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#fbd3da"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#bde6ab"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffe15f"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#efd151"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "black"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.airport",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#cfb2db"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#a2daf2"
                            }
                        ]
                    }
                ]
    };

    map = new google.maps.Map( document.getElementById( "map_canvas" ), mapOptions );

    /*****************************/
    /********** MARKERS **********/
    /*****************************/

    var infoWindow = new google.maps.InfoWindow(), marker, i;
    
    var setInfos = function( map, info, marker ) {

        return function() {
            infoWindow.setContent( info );
            infoWindow.open( map, marker );
        };

    };

    for ( i = 0; i < markers.length; i++ ) {

        var position = new google.maps.LatLng( markers[ i ][ 1 ], markers[ i ][ 2 ] );

        if ( centerBounds === true ) {
            bounds.extend(position);
        }

        marker = new google.maps.Marker({
            position: position,
            map: map,
            title: markers[i][0],
            icon: image
        });

        google.maps.event.addListener( marker, 'click', setInfos( map, infoWindowContent[ i ][ 0 ], marker ) );

        if ( centerBounds === true ) {
            map.fitBounds( bounds );
        }

    //END for ( i = 0; i < markers.length; i++ )
    }

    /**************************/
    /********** ZOOM **********/
    /**************************/

    if ( centerBounds === true ) {

        var boundsListener = google.maps.event.addListener(( map ), 'bounds_changed', function( event ) {
            this.setZoom(8);
            google.maps.event.removeListener( boundsListener );
        });

    //END if ( centerBounds == true )
    }

//END googleMapInitialize
}

/********************************************************************************/
/********************************************************************************/

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery(document).ready(function() {

    if ( jQuery('#map_canvas').length > 0 ) {

        googleMapInitialize();

    //END if ( jQuery('#map_canvas').length > 0 )
    }

//END jQuery(document).ready
});
