/**
 * Sambiagio - Scroll To Top
 *
 * JS pour la gestion du Scroll To Top
 *
 * @since       13.10.2016
 * @version     1.0.0.0
 * @author      e-novinfo
 * @copyright   e-novinfo 2016
 */

/*******************************/
/********** FUNCTIONS **********/
/*******************************/

/************************************************/
/********** AFFICHAGE DU SCROLL TO TOP **********/
/************************************************/

/*
 * @param Int pos                       position du scroll
 * @param Int limit                     position à partir de laquelle le lien est affiché
 */

var displayScrollTop = function (pos,limit) {

    if ( pos >= limit ) {

        jQuery('.scroll-to-top').fadeIn(400);

    }
    else {

        jQuery('.scroll-to-top').fadeOut(400);
    }

//END displayScrollTop
};

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/************************************/
/********** DOCUMENT READY **********/
/************************************/

jQuery(document).ready(function() {

    /***********************************/
    /********** SCROLL TO TOP **********/
    /***********************************/

    /*
     * Retour en haut de page au click sur le Scroll To Top
     */

    jQuery(document).on("click",".scroll-to-top",function(event) {

        event.preventDefault();

        jQuery('html, body').animate({scrollTop:0}, 'normal');

        return false;

    //END jQuery(document).on("click",".scroll-to-top",function(event)
    });

    posScroll = jQuery(document).scrollTop();

    displayScrollTop(posScroll, 100);

//END jQuery(document).ready
});

/*********************************************************************************************************************/
/*********************************************************************************************************************/

/***********************************/
/********** WINDOW SCROLL **********/
/***********************************/

jQuery(window).scroll(function(){

    /****************************************/
    /********** SHOW SCROLL TO TOP **********/
    /****************************************/

    /*
     * Apparition du bouton Scroll To Top après un scroll de X pixels
     */

    posScroll = jQuery(document).scrollTop();

    displayScrollTop(posScroll, 100);

//END jQuery(window).scroll
});
